export enum NorceWarehouseCodes {
  Standard = 'STDNO',
}

export enum NorceProductStatusCodes {
  Active = 'active',
  Coming = 'coming',
  Hidden = 'hidden',
  Expired = 'expired',
}

export enum NorceRelatedProductTypeCodes {
  Replacer = 'erstatningsprodukter',
  Upsell = 'upsel',
  Related = 'related-products',
  BoughtTogether = 'bought-together',
  ColorHydrogen = 'color-hydrogen',
  ColorMaterial = 'color-material',
  ColorBleach = 'color-bleach',
}

export enum NorceMediaSetCodes {
  DefaultImages = 'DEFAULT_IMAGES',
  SecondaryModelImages = 'SECONDARY_MODEL_IMAGES',
  Video = 'PRODUCT_VIDEOS',
  UserManual = 'user_manual',
  Msds = 'msds',
  MainVideo = 'main_video',
  AdditionalImages = 'additional_images',
}

export enum NorceParametricCodes {
  Color = 'COLOR',
  Size = 'SIZE',
  WashingInstructions = 'WASHING',
  SizeGuide = 'SIZE_GUIDE',

  MetaTitle = 'META_TITLE',
  MetaTitleOverride = 'META_TITLE_OVERRIDE',
  MetaDescription = 'META_DESCRIPTION',
  MetaDescriptionOverride = 'META_DESCRIPTION_OVERRIDE',
  DefaultImagesText = 'DEFAULT_IMAGES_TEXT',
  SecondaryModelImagesText = 'SECONDARY_MODEL_IMAGES_TEXT',
  ProductUnavailable = 'PRODUCT_UNAVAILABLE',

  Ingredients = 'ingredients',
  Characteristics = 'egenskaper',
  Volume = 'volume',
  SalesRepNo = 'salesrep-discount-no',
  ProductLayout = 'productLayout',
}

export enum NorceFlagGroupCodes {
  Promotions = 'PROMOTIONS',
  DisplayFlags = 'displayFlags',
  Splashes = 'splashes',
  ProductStatus = 'productStatus',
  StepCampaigns = 'stepCampaigns',
  GiftCampaigns = 'giftCampaigns',
  PercentageCampaigns = 'percentageCampaigns',
}

export enum NorceFlagCodes {
  DiscontinuedAndVisible = 'discontinuedAndVisible',
  OnDemand = 'onDemand',
  PreOrder = 'preOrder',
  IsNew = 'nyhet',
  Hidden = 'hidden',
}

export enum NorceInfoTypesCodes {
  BasketType = 'basketType',
  SalesRepComment = 'salesRepComment',
  SalesRepDiscount = 'salesRepDiscount',
  PossibleSalesRepGift = 'possibleSalesRepGift',
  PointShopProduct = 'pointShop',
  PointShopValue = 'pointShopValue',
  InvoiceNotice = 'invoiceNotice',
  Comment = 'comment',
}

export enum NorceBasketTypes {
  OrderProposal = 'orderProposal',
}

export enum NorceParametricGroupCodes {
  PdpDisplay = 'PDP_DISPLAY',
}

export enum NorceMediaTypes {
  Image = 'image',
  Video = 'video',
}

export enum NorcePriceTypes {
  Standard = 'standard',
  Campaign = 'campaign',
  Agreement = 'agreement',
}

export enum NorceOrderStatusCodes {
  Created = 'CREATED',
  Allocation = 'ALLOCATION',
  Confirmed = 'CONFIRMED',
  BackOrder = 'BACK ORDER',
  Reserved = 'RESERVED',
  Invoiced = 'INVOICED',
  Delivered = 'DELIVERED',
  PartlyDelivered = 'PARTLY DELIVERED',
  Cancelled = 'CANCELLED',
  Aborted = 'ABORTED',
}

export enum NorceUserTypes {
  User = 'user',
  Admin = 'admin',
  Finance = 'finance',
  Sales = 'sales',
  /**
   * this is extremly confusing but a customer support is called customer success
   */
  CustomerSuccess = 'customerSuccess',
}
